/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
 
  SET_Privacys(state, Privacys) {
    debugger;
    try {
    
      state.Privacys = Privacys;
     
    } catch (error) {
      console.error("Failed to parse Privacys data JSON:", error);
      state.Privacys = [];
    }
  },
  
}
