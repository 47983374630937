<template>
  <div id="data-list-list-view" class="data-list-container">
    <!-- Ordered List to display data -->
    <ol v-if="Privacys && Privacys.length">
      <li v-for="(privacy, index) in Privacys" :key="index">
        {{ privacy.NameAR }}
      </li>
    </ol>
    <p v-else>No data available</p>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import modulePrivacy from "@/store/settings/Privacy/modulePrivacy.js";

export default {
  data() {
    return {
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Privacys() {
      return this.$store.state.PrivacyList.Privacys;
    },
  },
  created() {
    if (!modulePrivacy.isRegistered) {
      this.$store.registerModule("PrivacyList", modulePrivacy);
      modulePrivacy.isRegistered = true;
    }
    this.$store.dispatch("PrivacyList/fetchDataListItems");
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  ol {
    list-style-type: decimal; /* Ensures numbered list */
    padding-left: 20px;
    li {
      margin-bottom: 10px;
    }
  }
}
</style>
