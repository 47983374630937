/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  

  fetchDataListItems({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/TermsAndCondition/GetAll",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_Privacys', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  
}
